<template>
  <footer id="footer" class="wrapper alt">
    <div class="inner">
      <ul class="menu">
        <li>&copy; Testmail. All rights reserved.</li>
        <li>Design: <a href="http://html5up.net" target="_blank">HTML5 UP</a></li>
        <li><a target="_blank" href="https://github.com/testmail-top/Usage">GitHub</a></li>
      </ul>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'FooterComponent',
  setup() {

    return {}
  }
}
</script>

<style lang="scss" scoped>

</style>